import React from "react";
import { Table } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const HoldingsTable = ({ snapshot }) => {
  const holdingsColumns = [
    {
      dataIndex: "symbol",
      key: "symbol",
      render: (symbol) => {
        return (
          <div>
            <div>{symbol}</div>
            <div style={{ color: "grey", fontSize: "10px" }}>Ticker</div>
          </div>
        );
      },
    },
    {
      title: "Average price",
      align: "right",
      key: "averagePurchasePrice",
      render: (position) => {
        const { averagePurchasePrice, currency } = position;
        return (
          <div>
            <div>${averagePurchasePrice}</div>
            <div style={{ color: "grey", fontSize: "10px" }}>{currency}</div>
          </div>
        );
      },
    },
    {
      title: "Today's price",
      align: "right",
      key: "latestMarketPrice",
      render: (position) => {
        const { latestMarketPrice, currency } = position;
        return (
          <div>
            <div>${latestMarketPrice}</div>
            <div style={{ color: "grey", fontSize: "10px" }}>{currency}</div>
          </div>
        );
      },
    },
    {
      title: "Total value",
      align: "right",
      key: "marketValueOfPosition",
      render: (position) => {
        const { latestMarketPrice, quantity } = position;

        return (
          <div>
            <div>${(latestMarketPrice * quantity).toFixed(2)}</div>
            <div style={{ color: "grey", fontSize: "10px" }}>
              {quantity} share{quantity === 1 ? "" : "s"}
            </div>
          </div>
        );
      },
    },
    {
      title: "All time return",
      align: "right",
      key: "allTimeReturn",
      render: (position) => {
        const { averagePurchasePrice, latestMarketPrice, quantity } = position;

        const marketValueOfPosition = latestMarketPrice * quantity;
        const costOfPosition = averagePurchasePrice * quantity;
        const allTimeReturn = (marketValueOfPosition - costOfPosition).toFixed(
          2
        );

        return (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {allTimeReturn > 0 ? (
                <CaretUpOutlined
                  style={{
                    marginRight: "5px",
                    color: "green",
                  }}
                ></CaretUpOutlined>
              ) : allTimeReturn < 0 ? (
                <CaretDownOutlined
                  style={{
                    marginRight: "5px",
                    color: "red",
                  }}
                ></CaretDownOutlined>
              ) : null}

              <div>${allTimeReturn}</div>
            </div>
            <div style={{ color: "grey", fontSize: "10px" }}>
              {(
                ((marketValueOfPosition - costOfPosition) / costOfPosition) *
                100
              ).toFixed(2)}
              %
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <h2>Holdings</h2>
      <Table
        dataSource={snapshot && snapshot.positions}
        columns={holdingsColumns}
        pagination={false}
        style={{
          marginBottom: "20px",
          marginTop: "20px",
        }}
        loading={snapshot === null}
      />
    </>
  );
};

export default HoldingsTable;
