import React, { useEffect, useState } from "react";
import { Avatar, Checkbox, Table, Tag, Button } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { brokerageLogoMap } from "./Utils";
import Request from "RequestHandler";

const defaultTypes = ["BUY", "SELL", "DIVIDEND"];

const TransactionsTable = ({ userId, portfolioId }) => {
  const [recentTransactions, setRecentTransactions] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([...defaultTypes]);

  useEffect(() => {
    getRecentTransactions();
  }, [selectedTypes]);

  const handleChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedTypes([...selectedTypes, value]);
    } else {
      setSelectedTypes(selectedTypes.filter((type) => type !== value));
    }
  };

  async function getRecentTransactions() {
    const queryParams = new URLSearchParams({
      type: selectedTypes.join(","),
      limit: 10,
    });
    const { res, err } = await Request.GET(
      `/user/${userId}/portfolio/${portfolioId}/transactions?${queryParams}`
    );
    if (err) return console.log(err);
    setRecentTransactions(res);
  }

  const holdingsColumns = [
    {
      key: "symbol",
      render: (t) => {
        return (
          <div style={{ display: "flex" }}>
            <Avatar
              size={40}
              style={{ marginRight: "8px" }}
              src={brokerageLogoMap[t.institution]}
              alt="Brokerage Logo"
              draggable={false}
            ></Avatar>
            <div>
              <div>{t.symbol}</div>
              <div style={{ color: "grey", fontSize: "10px" }}>Ticker</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (symbol) => {
        return (
          <div>
            <Tag>{symbol}</Tag>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (symbol) => {
        return (
          <div>
            <div>{symbol}</div>
          </div>
        );
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (symbol) => {
        return (
          <div>
            <Tag>{symbol}</Tag>
          </div>
        );
      },
    },
    {
      title: "Exchange",
      dataIndex: "exchange",
      key: "exchange",
      render: (symbol) => {
        return (
          <div>
            <Tag>{symbol}</Tag>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "settlementDate",
      key: "settlementDate",
      render: (symbol) => {
        return (
          <div>
            <div>{new Date(symbol).toDateString()}</div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>Recent Transactions</h2>
        <div>
          <Checkbox value="BUY" defaultChecked onChange={handleChange}>
            Buy
          </Checkbox>
          <Checkbox value="SELL" defaultChecked onChange={handleChange}>
            Sell
          </Checkbox>
          <Checkbox value="DIVIDEND" defaultChecked onChange={handleChange}>
            Dividend
          </Checkbox>
        </div>
      </div>
      <Table
        dataSource={recentTransactions}
        columns={holdingsColumns}
        pagination={false}
        style={{
          marginBottom: "20px",
          marginTop: "20px",
        }}
        loading={!recentTransactions}
      />
    </>
  );
};

export default TransactionsTable;
