import React from "react";
import InvestmentAccountsTable from "./subcomponents/InvestmentAccountsTable";
import PortfoliosTable from "./subcomponents/PortfoliosTable";

const Portfolio = () => {
  return (
    <>
      <PortfoliosTable />
      <InvestmentAccountsTable />
    </>
  );
};

export default Portfolio;
