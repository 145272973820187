import React from "react";
import { Layout } from "antd";
import "./index.css";

const UnauthNavbar = () => {
  const { Header } = Layout;
  return (
    <Header
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0.5px 5px 0px rgba(0,0,0,1)",
        display: "flex",
        alignItems: "center",
        height: "75px",
      }}
    >
      <img
        src={require("../../assets/images/logos/selfolio.png")}
        alt="logo"
        style={{
          height: "22.5px",
          marginLeft: "-20px",
        }}
      />
    </Header>
  );
};

export default UnauthNavbar;
