import { Card, Col, Divider, Row } from "antd";
import { useState } from "react";
import MiniChangeOverTimeLineGraph from "./MiniChangeOverTimeLineGraph";
import PortfolioCard from "./PortfolioCard";

const PortfoliosGrid = ({ portfolios }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  return (
    <Row gutter={[16, 24]} style={{ marginTop: "24px" }}>
      {portfolios?.map((portfolio) => (
        <Col span={windowWidth > 1210 ? 12 : 24} key={portfolio.id}>
          <PortfolioCard portfolio={portfolio} />
        </Col>
      ))}
    </Row>
  );
};

export default PortfoliosGrid;
