import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "Contexts";
import { Table, Button, Typography, Switch } from "antd";
import Request from "RequestHandler";
import { RightOutlined } from "@ant-design/icons";
import CreatePortfolioModal from "./CreatePortfolioModal";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd/es";
import { brokerageLogoMap } from "./Utils";

const PortfoliosTable = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const [portfolios, setPortfolios] = useState(null);
  const [isCreatePortfolioModalVisible, setIsCreatePortfolioModalVisible] =
    useState(false);

  useEffect(() => {
    getPortfolios();
  }, []);

  const portfoliosColumns = [
    {
      title: "Brokerage Logos",
      dataIndex: "investmentAccounts",
      key: "investmentAccountsInstitutionNames",
      width: "0px",
      align: "center",
      render: (investmentAccounts) => {
        return (
          <Avatar.Group
            maxCount={investmentAccounts?.length <= 3 ? 3 : 2}
            size="large"
          >
            {investmentAccounts?.map((investmentAccount) => {
              return (
                <Avatar
                  size={40}
                  src={brokerageLogoMap[investmentAccount.institutionName]}
                  alt="Brokerage Logo"
                  draggable={false}
                ></Avatar>
              );
            })}
          </Avatar.Group>
        );
      },
    },
    {
      title: "Portfolio Name",
      dataIndex: "name",
      key: "name",
      render: (name) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div>
              <div style={{ color: "grey", fontSize: "10px" }}>Name</div>
              <div>{name}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Porfolio isDiscoverable",
      dataIndex: "isDiscoverable",
      key: "isDiscoverable",
      render: (isDiscoverable, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <div
                  style={{
                    color: "grey",
                    fontSize: "10px",
                  }}
                >
                  Public?
                </div>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    patchPortfolioDiscoverability(
                      record.id,
                      !record.isDiscoverable
                    );
                  }}
                  style={{
                    paddingRight: "36px",
                  }}
                >
                  <Switch
                    checkedChildren="on"
                    unCheckedChildren="off"
                    checked={isDiscoverable}
                  />
                </span>
              </div>
            </div>
            <RightOutlined style={{ color: "gray" }} />
          </div>
        );
      },
    },
  ];

  async function getPortfolios() {
    const { res, err } = await Request.GET(`/user/${user.id}/portfolio`);
    if (err) return console.log(err);

    setPortfolios(res);
  }

  async function patchPortfolioDiscoverability(portfolioId, isDiscoverable) {
    const queryParams = new URLSearchParams({
      isDiscoverable: isDiscoverable ? "true" : "false",
    });
    const { res, err } = await Request.POST(
      `/user/${user.id}/portfolio/${portfolioId}/discoverable?${queryParams}`
    );
    if (err) return console.log(err);

    const updatedPortfolios = portfolios.map((portfolio) => {
      if (portfolio.id === portfolioId) {
        portfolio.isDiscoverable = !portfolio.isDiscoverable;
      }
      return portfolio;
    });
    setPortfolios(updatedPortfolios);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h2>My Portfolios</h2>
        <Button
          size="small"
          onClick={() => {
            setIsCreatePortfolioModalVisible(true);
          }}
          style={{ fontSize: "10px" }}
        >
          Create Portfolio
        </Button>
      </div>

      <Table
        showHeader={false}
        dataSource={portfolios}
        columns={portfoliosColumns}
        pagination={false}
        style={{
          marginBottom: "20px",
        }}
        loading={portfolios === null}
        onRow={(portfolio, rowIndex) => {
          return {
            onClick: () => {
              navigate(`/portfolio/${portfolio.id}`);
            },
          };
        }}
      />
      <CreatePortfolioModal
        visible={isCreatePortfolioModalVisible}
        onCancel={() => {
          setIsCreatePortfolioModalVisible(false);
        }}
        setIsCreatePortfolioModalVisible={setIsCreatePortfolioModalVisible}
        userId={user.id}
        portfolios={portfolios}
        setPortfolios={setPortfolios}
        getPortfolios={getPortfolios}
      />
    </>
  );
};

export default PortfoliosTable;
