const fetchAPI = async (url, options) => {
  options = {
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  };
  console.log("NODE_ENV", process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production") {
    url = "https://api.selfolio.ca" + url;
  }

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return { res: data, err: null };
  } catch (err) {
    return { res: null, err };
  }
};

class RequestHandler {
  async POST(url, options) {
    return await fetchAPI(url, { ...options, method: "POST" });
  }

  async GET(url, options) {
    return await fetchAPI(url, { ...options, method: "GET" });
  }

  async PATCH(url, options) {
    return await fetchAPI(url, { ...options, method: "PATCH" });
  }

  async UPDATE(url, options) {
    return await fetchAPI(url, { ...options, method: "UPDATE" });
  }

  async DELETE(url, options) {
    return await fetchAPI(url, { ...options, method: "DELETE" });
  }
}

const Request = new RequestHandler();
export default Request;
