import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/plots";

const HoldingsPieChart = ({ positions }) => {
  const data = [];

  positions?.forEach((position) => {
    data.push({
      type: position.symbol,
      name: position.symbol,
      value: position.quantity * position.latestMarketPrice,
    });
  });

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.75,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name}\n{percentage}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Pie {...config} style={{ width: "600px" }} />
    </div>
  );
};

export default HoldingsPieChart;
