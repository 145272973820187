import React, { useState, useEffect, useContext } from "react";
import { Line } from "@ant-design/plots";

const MiniChangeOverTimeLineGraph = ({ oneYearGraphData }) => {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (oneYearGraphData) {
      setGraphData(formatGraphData(oneYearGraphData));
    }
  }, [oneYearGraphData]);

  const formatGraphData = (data) => {
    const formattedData = [];

    for (let [key, value] of Object.entries(data)) {
      formattedData.push({
        name: "Market Value of Positions",
        date: new Date(key).toDateString(),
        value: value.marketValueOfPositions,
      });
      formattedData.push({
        name: "Cost of Positions",
        date: new Date(key).toDateString(),
        value: value.costOfPositions,
      });
    }

    return formattedData.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const config = {
    data: graphData,
    xField: "date",
    yField: "value",
    seriesField: "name",
    xAxis: {
      label: false,
    },
    yAxis: {
      label: false,
      min: graphData.reduce(
        (min, p) => (p.value < min ? p.value : min),
        Infinity
      ),
    },
    legend: false,
    smooth: true,
    animation: {
      appear: {
        duration: 750,
      },
    },
    tooltip: {
      position: "right",
    },
    color: ["purple", "#5AD8A6"],
  };

  return <Line {...config} />;
};

export default MiniChangeOverTimeLineGraph;
