import { Table, Button, Typography, Input, message } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "Contexts";
import Request from "RequestHandler";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ConnectBrokeragesModal from "./ConnectBrokeragesModal";
import { useLocation } from "react-router-dom";
import { Avatar } from "antd/es";
import { brokerageLogoMap } from "./Utils";

const { Title } = Typography;

const InvestmentAccountsTable = () => {
  const user = useContext(UserContext);

  const [editableAccounts, setEditableAccounts] = useState(new Set());
  const [investmentAccountNames, setInvestmentAccountNames] = useState({});
  const [brokerages, setBrokerages] = useState([]);
  const [isFetchingBrokerages, setIsFetchingBrokerages] = useState(true);
  const [isConnectBrokeragesModalVisible, setIsConnectBrokeragesModalVisible] =
    useState(false);
  const [investmentAccounts, setInvestmentAccounts] = useState(null);

  const queryParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    setInvestmentAccountNames(
      investmentAccounts?.reduce((acc, investmentAccount) => {
        acc[investmentAccount.id] = investmentAccount.name;
        return acc;
      }, {})
    );
  }, [investmentAccounts]);

  useEffect(() => {
    async function getBrokerages() {
      const { res, err } = await Request.GET("/investments/brokerages");
      if (err) console.log(err);
      else {
        setBrokerages(res);
        setIsFetchingBrokerages(false);
      }
    }
    if (isFetchingBrokerages) getBrokerages();
  }, [isFetchingBrokerages, setIsFetchingBrokerages]);

  useEffect(() => {
    const successfullyLinkedBrokerage =
      queryParams.get("source") === "snaptrade";

    if (successfullyLinkedBrokerage) {
      syncInvestmentAccounts();
      window.history.pushState({}, document.title, "/portfolio");
    } else if (!investmentAccounts) {
      getInvestmentAccounts();
    }
  }, []);

  async function syncInvestmentAccounts() {
    const { res, err } = await Request.POST(
      `/user/${user.id}/investmentAccount`
    );
    if (err) return console.log(err);
    message.info(
      "Your account has been connected! Please wait a few minutes for your data to sync."
    );
  }

  async function getInvestmentAccounts() {
    const { res, err } = await Request.GET(
      `/user/${user.id}/investmentAccount`
    );
    if (err) return console.log(err);
    setInvestmentAccounts(res);
  }

  const investmentAccountsColumns = [
    {
      title: "Brokerage Logo",
      dataIndex: "institutionName",
      key: "brokerageLogo",
      width: "0px",
      align: "center",
      render: (institutionName) => {
        return (
          <Avatar
            size={40}
            src={brokerageLogoMap[institutionName]}
            alt="Brokerage Logo"
            draggable={false}
          ></Avatar>
        );
      },
    },
    {
      title: "Account Name",
      key: "name",
      render: (account) => {
        return (
          <div>
            <div style={{ color: "grey", fontSize: "10px" }}>Name</div>
            {editableAccounts?.has(account.id) ? (
              <Input
                style={{ fontSize: "15px" }}
                value={investmentAccountNames[account.id]}
                onChange={(e) => {
                  setInvestmentAccountNames({
                    ...investmentAccountNames,
                    [account.id]: e.target.value,
                  });
                }}
              />
            ) : (
              <div
                style={{
                  fontSize: "15px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                {investmentAccountNames && investmentAccountNames[account.id]}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (account) => (
        <div>
          {editableAccounts?.has(account.id) ? (
            <CheckOutlined
              style={{
                marginRight: "10px",
                color: "#1890ff",
                fontSize: "16px",
              }}
              onClick={async () => {
                const newEditableAccounts = new Set(editableAccounts);
                newEditableAccounts.delete(account.id);
                setEditableAccounts(newEditableAccounts);

                updateInvestmentAccountName(account.id);
              }}
            />
          ) : (
            <EditOutlined
              style={{
                marginRight: "10px",
                color: "#1890ff",
                fontSize: "16px",
              }}
              onClick={() => {
                setEditableAccounts(new Set(editableAccounts).add(account.id));
              }}
            >
              Edit
            </EditOutlined>
          )}
          {editableAccounts?.has(account.id) ? (
            <CloseOutlined
              style={{ color: "red", fontSize: "16px" }}
              onClick={async () => {
                const newEditableAccounts = new Set(editableAccounts);
                newEditableAccounts.delete(account.id);
                setEditableAccounts(newEditableAccounts);

                setInvestmentAccountNames({
                  ...investmentAccountNames,
                  [account.id]: account.name,
                });
              }}
            />
          ) : (
            <DeleteOutlined
              style={{ color: "red", fontSize: "16px" }}
              onClick={() => console.log(account)}
            >
              Delete
            </DeleteOutlined>
          )}
        </div>
      ),
    },
  ];

  async function updateInvestmentAccountName(accountId) {
    const { res, err } = await Request.PATCH(
      `/user/${user.id}/investmentAccount/${accountId}/name`,
      {
        body: JSON.stringify({
          name: investmentAccountNames[accountId],
        }),
      }
    );
    if (err) return console.log(err);
    investmentAccountNames[accountId] = res.name;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h2>My Investment Accounts</h2>
        <Button
          size="small"
          onClick={() => {
            setIsConnectBrokeragesModalVisible(true);
          }}
          style={{ fontSize: "10px" }}
          loading={isFetchingBrokerages}
        >
          Connect Brokerage
        </Button>
      </div>
      <Table
        showHeader={false}
        dataSource={investmentAccounts}
        columns={investmentAccountsColumns}
        pagination={false}
        loading={investmentAccounts === null}
      />
      <ConnectBrokeragesModal
        visible={isConnectBrokeragesModalVisible && !isFetchingBrokerages}
        onCancel={() => {
          setIsConnectBrokeragesModalVisible(false);
        }}
        userId={user.id}
        brokerages={brokerages}
      />
    </>
  );
};

export default InvestmentAccountsTable;
