import { Select, Button, Typography, message } from "antd";
import React, { useContext, useState } from "react";
import { UserContext } from "Contexts";
import Request from "RequestHandler";

const { Option } = Select;

const Settings = () => {
  const user = useContext(UserContext);

  const [displayCurrency, setDisplayCurrency] = useState(user.displayCurrency);

  const handleChange = (newCurrency) => {
    setDisplayCurrency(newCurrency);
  };

  const handleSubmit = () => {
    updateDisplayCurrency();
    message.success("Your settings have updated successfully!");
  };

  async function updateDisplayCurrency() {
    if (user.displayCurrency === displayCurrency) return;

    const { res, err } = await Request.PATCH(
      `/user/${user.id}/settings/displayCurrency`,
      {
        body: JSON.stringify({
          displayCurrency,
        }),
      }
    );
    if (err) return console.log(err);

    user.displayCurrency = displayCurrency;
  }

  return (
    <div>
      <h2>My Settings</h2>
      <div
        style={{
          display: "flex",
          marginTop: "16px",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <Typography level={1} style={{ marginRight: "10px" }}>
          Display Currency:
        </Typography>
        <Select
          value={displayCurrency}
          onChange={handleChange}
          style={{ width: 80 }}
        >
          <Option value="CAD">CAD</Option>
          <Option value="USD">USD</Option>
        </Select>
      </div>
      <Button
        type="primary"
        onClick={handleSubmit}
        style={{ width: 80, float: "right" }}
      >
        Submit
      </Button>
    </div>
  );
};

export default Settings;
