import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import ConfirmSignUp from "./ConfirmSignUp";
import Request from "RequestHandler";

const Signup = () => {
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    given_name: "",
    family_name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isSignupSubmitDisabled =
    !formState.username ||
    !formState.password ||
    !formState.given_name ||
    !formState.family_name;

  const handleSignUp = async () => {
    if (isSignupSubmitDisabled) return;

    setError(null);
    setIsLoading(true);
    const { username, password, given_name, family_name } = formState;
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: { given_name, family_name },
      });
      const cognitoUserId = user.pool.clientId;

      const { err } = Request.POST("/user", {
        body: JSON.stringify({
          cognitoUserId,
          firstName: given_name,
          lastName: family_name,
          email: username,
        }),
      });
      if (err) throw err;
      sessionStorage.setItem("email", username);
    } catch (error) {
      setError(`${error}`);
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const email = sessionStorage.getItem("email");
  if (email?.length)
    return <ConfirmSignUp email={email} password={formState.password} />;

  return (
    <div className="center-container">
      <div className="auth-card">
        <div className="auth-card-header">
          <h1>
            Get started with <br />
            Selfolio
          </h1>
          <h6>One login for all Selfolio products</h6>
        </div>
        <Input
          name="given_name"
          onChange={handleChange}
          onPressEnter={handleSignUp}
          type="text"
          placeholder="First name"
          style={{ marginBottom: "8px" }}
        />
        <Input
          name="family_name"
          onChange={handleChange}
          onPressEnter={handleSignUp}
          type="text"
          placeholder="Last name"
          style={{ marginBottom: "8px" }}
        />
        <Input
          name="username"
          onChange={handleChange}
          onPressEnter={handleSignUp}
          type="text"
          placeholder="Email"
          style={{ marginBottom: "8px" }}
        />
        <Input.Password
          name="password"
          onChange={handleChange}
          onPressEnter={handleSignUp}
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          style={{ marginBottom: "8px" }}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Button
          disabled={isSignupSubmitDisabled}
          onClick={() => handleSignUp()}
          type="primary"
          style={{ width: "100%", marginTop: "24px" }}
          loading={isLoading}
        >
          {!isLoading ? "Sign up" : "Loading..."}
        </Button>
        <div className="auth-card-create-account">
          <p>
            Already have an account? <a href="/login">Log in</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
