import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";

const ConfirmSignUp = ({ email, password }) => {
  const [formState, setFormState] = useState({
    username: email,
    password: password,
    authCode: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const isConfirmSubmitDisabled = !formState.authCode || !formState.username;

  const handleChange = (e) => {
    setFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const handleSubmitVerificationCode = async () => {
    if (isConfirmSubmitDisabled) return;

    setError(null);
    setIsLoading(true);
    const { username, authCode, password } = formState;
    try {
      await Auth.confirmSignUp(username, authCode);
      sessionStorage.removeItem("email");
      if (password) {
        await Auth.signIn(username, password);
        window.location.reload();
        return;
      }
    } catch (error) {
      setError(`${error}`);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    navigate("/login");
  };

  const handleResendVerificationCode = async () => {
    setIsResendLoading(true);
    const { username } = formState;
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      setError(`${error}`);
    }
    setIsResendLoading(false);
  };

  return (
    <div className="center-container">
      <div className="auth-card">
        <div className="auth-card-header">
          <h1>Verify your email</h1>
          <h6>One login for all Selfolio products</h6>
        </div>
        <Input
          name="username"
          onChange={handleChange}
          type="text"
          placeholder="Email"
          value={formState.username}
          style={{ marginBottom: "8px" }}
          disabled
        />
        <Input
          name="authCode"
          onChange={handleChange}
          onPressEnter={handleSubmitVerificationCode}
          type="text"
          placeholder="Verification code"
        />
        <Button
          icon={<ReloadOutlined />}
          type="link"
          style={{ marginLeft: "-12px", marginBottom: "8px" }}
          onClick={() => handleResendVerificationCode()}
          loading={isResendLoading}
        >
          Resend verification code
        </Button>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Button
          disabled={isConfirmSubmitDisabled}
          onClick={() => handleSubmitVerificationCode()}
          type="primary"
          style={{ width: "100%", marginTop: "24px" }}
          loading={isLoading}
        >
          {!isLoading ? "Submit" : "Loading..."}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmSignUp;
