import React, { useEffect, useState } from "react";
import { SELFOLIO_ROUTES } from "./routes";
import { CognitoUserContext, UserContext } from "./Contexts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider, Layout } from "antd";
import Navbar from "./components/navbar";
import ValidRoute from "./components/ValidRoute";
import Sidebar from "./components/Sidebar";

import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import Request from "RequestHandler";

Amplify.configure(awsExports);

export default function App() {
  const [loading, setLoading] = useState({ cognitoUser: true, user: true });
  const [cognitoUser, setCognitoUser] = useState(null);
  const [user, setUser] = useState(null);

  const setCurrentAuthenticatedUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setCognitoUser(cognitoUser);
      return cognitoUser;
    } catch (error) {
      console.log(error);
    }
  };

  const setUserContext = async (email) => {
    if (!email?.length) return;
    const encodedEmail = encodeURIComponent(email);

    const { res, err } = await Request.GET(`/user?email=${encodedEmail}`);
    if (err) return console.log(err);
    setUser(res);
  };

  useEffect(() => {
    async function execute() {
      const cognitoUser = await setCurrentAuthenticatedUser();
      setLoading((prev) => ({ ...prev, cognitoUser: false }));
      await setUserContext(cognitoUser?.attributes?.email);
      setLoading((prev) => ({ ...prev, user: false }));
    }
    execute();
  }, []);

  if (loading.cognitoUser) return <div>Loading...</div>;

  const isAuthenticated = !!cognitoUser;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#9e9bc3",
          colorPrimaryActive: "#9e9bc3",
          colorPrimaryHover: "#9e9bc3",
          colorPrimaryBg: "#9e9bc3",
          colorPrimaryBgHover: "#9e9bc3",
        },
      }}
    >
      <CognitoUserContext.Provider value={cognitoUser}>
        <UserContext.Provider value={user}>
          <Router>
            <Layout style={{ height: "100vh" }}>
              <Layout>
                {isAuthenticated && <Sidebar />}
                <Layout
                  style={{
                    padding: isAuthenticated ? "0px 24px 24px" : "0px",
                    overflowY: "scroll",
                    // backgroundColor: "#fff",
                    // background gradiend from the top
                    background: "linear-gradient(white,white, white)",
                  }}
                >
                  {!loading.user && (
                    <Navbar isAuthenticated={isAuthenticated} />
                  )}
                  {loading.user ? (
                    <div>Loading...</div>
                  ) : (
                    <Routes>
                      {SELFOLIO_ROUTES.map((route, idx) => {
                        return (
                          <Route
                            key={idx}
                            path={route.path + "/*"}
                            element={
                              <ValidRoute
                                route={route}
                                isAuthenticated={isAuthenticated}
                              >
                                <route.component {...route.props} />
                              </ValidRoute>
                            }
                          />
                        );
                      })}
                      <Route
                        path="/"
                        element={<Navigate to="/login" replace />}
                      />
                    </Routes>
                  )}
                </Layout>
              </Layout>
            </Layout>
          </Router>
        </UserContext.Provider>
      </CognitoUserContext.Provider>
    </ConfigProvider>
  );
}
