import { UserContext } from "Contexts";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Request from "RequestHandler";
import { Tabs } from "antd";
import HoldingsPieChart from "./subcomponents/HoldingsPieChart";
import HoldingsTable from "./subcomponents/HoldingsTable";
import ChangeOverTimeLineGraph from "./subcomponents/ChangeOverTimeLineGraph";
import TransactionsTable from "./subcomponents/TransactionsTable";

const PortfolioDetails = () => {
  const { portfolioId } = useParams();
  const user = useContext(UserContext);
  const [snapshot, setSnapshot] = useState(null);
  const [currentTab, setCurrentTab] = useState("holdingss");

  useEffect(() => {
    getSnapshot();
  }, []);

  async function getSnapshot() {
    const { res, err } = await Request.GET(
      `/user/${user.id}/portfolio/${portfolioId}/snapshot`
    );
    if (err) return console.log(err);
    setSnapshot(res);
    // console.log("snapshot", res);
  }

  const onTabChange = (key) => {
    setCurrentTab(key);
  };

  return (
    <div>
      <Tabs
        onChange={onTabChange}
        type="card"
        items={[
          { label: "Change Over Time", key: "changeOverTime" },
          { label: "Holdings", key: "holdings" },
        ]}
      />
      {currentTab === "holdings" ? (
        <>
          <HoldingsPieChart positions={snapshot && snapshot.positions} />
          <HoldingsTable snapshot={snapshot} />
        </>
      ) : (
        <>
          <ChangeOverTimeLineGraph snapshot={snapshot} />
          <TransactionsTable userId={user.id} portfolioId={portfolioId} />
        </>
      )}
    </div>
  );
};

export default PortfolioDetails;
