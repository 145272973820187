import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { Auth } from "aws-amplify";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { UserContext } from "Contexts";

const { Header } = Layout;

const UnauthNavbar = () => {
  const location = useLocation();
  const user = useContext(UserContext);

  const [currPath, setCurrPath] = useState(
    window.location.pathname.split("/")[1]
  );

  useEffect(() => {
    setCurrPath(window.location.pathname.split("/")[1]);
  }, [location]);

  const menu = (
    <Menu style={{ marginTop: "20px" }}>
      <Menu.Item key="settings">
        <SettingOutlined style={{ marginRight: "10px" }} />
        <a href="/settings">Settings</a>
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined style={{ marginRight: "10px" }} />
        <a onClick={() => handleLogout()}>Sign Out</a>
      </Menu.Item>
    </Menu>
  );

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const formatPath = (path) => {
    if (path?.length > 0)
      return " " + path[0].toUpperCase() + path.substring(1);
  };

  return (
    <Header
      style={{
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "56px",
        marginTop: "16px",
        marginBottom: "16px",
        padding: "0 15px",
      }}
    >
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "4px", color: "grey" }}>Pages /</p>
        <p>{formatPath(currPath)}</p>
      </div>
      <div style={{ cursor: "pointer" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Avatar
              size="large"
              src={user?.profilePictureUrl}
              draggable={false}
            />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default UnauthNavbar;
