import PortfolioDetails from "components/portfolio/PortfolioDetails";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import Discover from "./components/discover/Discover";
import Portfolio from "./components/portfolio/Portfolio";
import Settings from "./components/Settings";

export const SELFOLIO_ROUTES = [
  {
    name: "Discover",
    path: "/discover",
    component: Discover,
    requiresAuth: true,
  },
  {
    name: "Portfolio",
    path: "/portfolio",
    component: Portfolio,
    requiresAuth: true,
  },
  {
    name: "PortfolioDetails",
    path: "/portfolio/:portfolioId",
    component: PortfolioDetails,
    requiresAuth: true,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    requiresAuth: false,
  },
  {
    name: "Signup",
    path: "/register",
    component: Signup,
    requiresAuth: false,
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
    requiresAuth: true,
  },
];
