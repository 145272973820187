import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import { UserContext } from "Contexts";
import Request from "RequestHandler";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { formatToDollars } from "./Utils";

const ChangeOverTimeLineGraph = ({ snapshot }) => {
  const { marketValueOfPositions, currency, costOfPositions } = snapshot
    ? snapshot
    : {};
  const { portfolioId } = useParams();
  const user = useContext(UserContext);

  const [graphData, setGraphData] = useState([]);
  const [duration, setDuration] = useState("1Y");

  useEffect(() => {
    getGraph();
  }, [duration]);

  async function getGraph() {
    const endDate = new Date().toISOString().split("T")[0];

    let startDate = new Date();

    if (duration === "Max") {
      startDate = new Date(0);
    } else if (duration === "YTD") {
      startDate = new Date(new Date().getFullYear(), 0, 1);
    } else {
      const durationToMonthsMap = {
        "1M": 1,
        "3M": 3,
        "6M": 6,
        "1Y": 12,
        "5Y": 60,
      };

      startDate.setMonth(startDate.getMonth() - durationToMonthsMap[duration]);
    }

    startDate = startDate.toISOString().split("T")[0];

    const queryParams = new URLSearchParams({ startDate, endDate });

    const { res, err } = await Request.GET(
      `/user/${user.id}/portfolio/${portfolioId}/graph?${queryParams}`
    );
    if (err) return console.log(err);
    setGraphData(formatGraphData(res));
    // console.log("graph", formatGraphData(res));
  }

  const formatGraphData = (data) => {
    const formattedData = [];

    for (let [key, value] of Object.entries(data)) {
      formattedData.push({
        name: "Market Value of Positions",
        date: new Date(key).toDateString(),
        value: value.marketValueOfPositions,
      });
      formattedData.push({
        name: "Cost of Positions",
        date: new Date(key).toDateString(),
        value: value.costOfPositions,
      });
      formattedData.push({
        name: "Dividends",
        date: new Date(key).toDateString(),
        value: value.dividends,
      });
    }

    return formattedData.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const onTabChange = (key) => {
    setDuration(key);
  };

  const config = {
    data: graphData,
    xField: "date",
    yField: "value",
    seriesField: "name",
    yAxis: {
      label: {
        formatter: (v) => `$${v}.00`,
      },
    },
    legend: {
      position: "top",
    },
    smooth: true,
    animation: {
      appear: {
        duration: 750,
      },
    },
    color: ["purple", "#5AD8A6", "#5C8FF8"],
  };

  const firstDayPL = config.data.length
    ? config.data[0].value - config.data[1].value
    : 0;
  const lastDayPL = config.data.length
    ? config.data[config.data.length - 3].value -
      config.data[config.data.length - 2].value
    : 0;
  const allTimeReturnNumeric = (lastDayPL - firstDayPL).toFixed(2);
  const allTimeReturnPercentage = (
    (allTimeReturnNumeric / costOfPositions) *
    100
  ).toFixed(2);

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div style={{ fontSize: "50px" }}>
          {formatToDollars(marketValueOfPositions)}
        </div>
        <div
          style={{
            fontSize: "19px",
            marginBottom: "5px",
            marginLeft: "7.5px",
            color: "gray",
          }}
        >
          {currency}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div
          style={{
            fontSize: "19px",
            marginRight: "2.5px",
            color:
              allTimeReturnNumeric > 0
                ? "green"
                : allTimeReturnNumeric < 0
                ? "red"
                : undefined,
          }}
        >
          {allTimeReturnNumeric > 0 ? (
            <CaretUpOutlined
              style={{
                marginRight: "2.5px",
                color: "green",
              }}
            ></CaretUpOutlined>
          ) : allTimeReturnNumeric < 0 ? (
            <CaretDownOutlined
              style={{
                marginRight: "2.5px",
                color: "red",
              }}
            ></CaretDownOutlined>
          ) : null}
          {(allTimeReturnNumeric > 0 ? "+" : "") +
            formatToDollars(allTimeReturnNumeric) +
            " (" +
            (allTimeReturnNumeric > 0 ? "+" : "") +
            allTimeReturnPercentage +
            "%)"}
        </div>
      </div>
      <Tabs
        defaultActiveKey="1Y"
        items={[
          {
            label: "1M",
            key: "1M",
          },
          {
            label: "3M",
            key: "3M",
          },
          {
            label: "6M",
            key: "6M",
          },
          {
            label: "YTD",
            key: "YTD",
          },
          {
            label: "1Y",
            key: "1Y",
          },
          {
            label: "5Y",
            key: "5Y",
          },
          {
            label: "Max",
            key: "Max",
          },
        ]}
        onChange={onTabChange}
      />
      <Line {...config} style={{ marginBottom: "30px" }} />
    </>
  );
};

export default ChangeOverTimeLineGraph;
