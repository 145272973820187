import { Modal, Input, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Request from "RequestHandler";
import "./CreatePortfolioModal.css";
import { Checkbox } from "antd";

const CreatePortfolioModal = ({
  visible,
  onCancel,
  userId,
  portfolios,
  setPortfolios,
  getPortfolios,
  setIsCreatePortfolioModalVisible,
}) => {
  const [portfolioName, setPortfolioName] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [investmentAccounts, setInvestmentAccounts] = useState(null);

  useEffect(() => {
    getInvestmentAccounts();
  }, []);

  async function getInvestmentAccounts() {
    const { res, err } = await Request.GET(`/user/${userId}/investmentAccount`);
    if (err) return console.log(err);
    setInvestmentAccounts(res);
  }

  const onCreate = async () => {
    const { res, err } = await Request.POST(`/user/${userId}/portfolio`, {
      body: JSON.stringify({
        name: portfolioName,
        investmentAccountIds: checkedList,
      }),
    });
    if (err) throw err;

    setPortfolios([...portfolios, res]);
    getPortfolios();
    setCheckedList([]);
    setPortfolioName("");
    setIsCreatePortfolioModalVisible(false);
  };

  const portfolioNames = new Set(
    portfolios?.map((portfolio) => portfolio.name)
  );

  const investmentAccountsOptions = [];

  if (investmentAccounts) {
    for (const investmentAccount of investmentAccounts) {
      investmentAccountsOptions.push({
        label: investmentAccount.name,
        value: investmentAccount.id,
      });
    }
  }

  return (
    <Modal
      title="Create Portfolio"
      open={visible}
      onCancel={() => {
        // this doesn't work:
        setShowErrorMessage(false);
        onCancel();
      }}
      footer={null}
      centered
    >
      <Tooltip
        open={showErrorMessage}
        title={
          portfolioName.length === 0
            ? "Portfolio name is too short."
            : "Portfolio name already exists."
        }
        placement="topLeft"
      >
        <Input
          placeholder="Portfolio Name"
          value={portfolioName}
          status={showErrorMessage ? "error" : ""}
          tooltip="This is a required field"
          onChange={(e) => {
            setShowErrorMessage(false);
            setPortfolioName(e.target.value);
          }}
        />
      </Tooltip>
      <p className="modalHeading">Select the accounts to include:</p>
      <Checkbox.Group
        options={investmentAccountsOptions}
        value={checkedList}
        onChange={(newCheckedList) => {
          setCheckedList(newCheckedList);
        }}
        style={{ marginLeft: "12px" }}
        className="checkbox-group"
      />
      <Button
        type="primary"
        style={{ alignSelf: "flex-end", marginTop: "12px" }}
        onClick={() => {
          if (portfolioNames.has(portfolioName) || portfolioName === "") {
            setShowErrorMessage(true);
          } else {
            onCreate();
          }
        }}
      >
        Create
      </Button>
    </Modal>
  );
};

export default CreatePortfolioModal;
