import { UserContext } from "Contexts";
import React, { useContext, useEffect, useState } from "react";
import Request from "RequestHandler";
import { Input, message } from "antd";
import PortfoliosGrid from "./subcomponents/PortfoliosGrid";

const { Search } = Input;

const Discover = () => {
  const user = useContext(UserContext);
  const [portfolios, setPortfolios] = useState(null);

  useEffect(() => {
    getPublicPortfolios();
  }, []);

  async function getPublicPortfolios() {
    const { res, err } = await Request.GET(
      `/portfolio?displayCurrency=${user.displayCurrency}`
    );
    if (err) return console.log(err);
    setPortfolios(res);
    // console.log("portfolios", res);
  }

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>Discover Portfolios</h2>
      <Search
        placeholder="Search for a portfolio"
        allowClear
        style={{ marginTop: "16px" }}
        onClick={() => message.info("Feature coming soon!")}
      />
      <PortfoliosGrid portfolios={portfolios} />
    </div>
  );
};

export default Discover;
