import { Modal, Row, Col } from "antd";
import React from "react";
import Request from "RequestHandler";
import "./ConnectBrokeragesModal.css";

const ConnectBrokeragesModal = ({ visible, onCancel, userId, brokerages }) => {
  const handleOnBrokerageClick = async (brokerageSlug) => {
    const { res, err } = await Request.POST(
      `/user/${userId}/redirectUri/${brokerageSlug}`
    );
    if (err) console.log(err);
    else {
      window.location.href = res.redirectUri;
    }
  };

  return (
    <Modal
      title="Connect Investment Account"
      open={visible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Row style={{ padding: 24 }} gutter={[16, 24]}>
        {brokerages?.map((brokerage, index) => (
          <Col
            span={12}
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="brokerage-card"
              onClick={() => handleOnBrokerageClick(brokerage.slug)}
            >
              <img
                src={brokerage.aws_s3_logo_url}
                alt={`Brokerage ${index + 1} logo`}
                style={{
                  width: "80%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ConnectBrokeragesModal;
