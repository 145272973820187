import { Avatar, Button, Card, Rate, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MiniChangeOverTimeLineGraph from "./MiniChangeOverTimeLineGraph";
import {
  UserAddOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { formatToDollars } from "components/portfolio/subcomponents/Utils";

const PortfolioCard = ({ portfolio }) => {
  const navigate = useNavigate();
  const { name, User: user, snapshot } = portfolio;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  const calculateMarketValues = () => {
    for (let i = 0; i < snapshot.positions.length; i++) {
      const position = snapshot.positions[i];
      position.marketValue = position.latestMarketPrice * position.quantity;
    }

    snapshot.positions.sort((a, b) => b.marketValue - a.marketValue);
  };

  const getExchanges = () => {
    const exchanges = new Set();
    snapshot.positions.forEach((position) => {
      exchanges.add(position.exchange);
    });

    return exchanges;
  };

  const handleClick = () => {
    navigate(`/portfolio/${portfolio.id}`);
  };

  calculateMarketValues();
  const exchanges = getExchanges();

  const allTimeReturnNumeric = (
    snapshot.marketValueOfPositions - snapshot.costOfPositions
  ).toFixed(2);
  const allTimeReturnPercentage = (
    (allTimeReturnNumeric / snapshot.costOfPositions) *
    100
  ).toFixed(2);

  return (
    <Card
      hoverable
      onClick={handleClick}
      style={{ cursor: "pointer", border: "1px solid #D9D9D9" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            src={user.profilePictureUrl}
            style={{ marginRight: "10px" }}
            size="large"
            draggable={false}
          ></Avatar>
          <div>
            <b style={{ fontSize: "20px" }}>{name}</b>
            <div style={{ color: "grey", fontSize: "10px" }}>
              {user.firstName} {user.lastName}
            </div>
          </div>
        </div>
        <Button
          shape="round"
          onClick={(e) => {
            message.info("Feature coming soon!");
            e.stopPropagation();
          }}
          style={{ height: "26px", width: "86px", fontSize: "11px" }}
        >
          <UserAddOutlined />
          Follow
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "-7.5px",
              }}
            >
              <b style={{ fontSize: "27.5px" }}>
                {formatToDollars(snapshot.marketValueOfPositions)}
              </b>
              <div
                style={{
                  color: "gray",
                  marginLeft: "4px",
                  marginBottom: "-10px",
                }}
              >
                {snapshot.currency}
              </div>
            </div>
            <div
              style={{
                fontSize: "13px",
                color:
                  allTimeReturnNumeric > 0
                    ? "green"
                    : allTimeReturnNumeric < 0
                    ? "red"
                    : undefined,
                marginBottom: "7.5px",
              }}
            >
              {(allTimeReturnNumeric > 0 ? "+" : "") +
                formatToDollars(allTimeReturnNumeric) +
                " (" +
                (allTimeReturnNumeric > 0 ? "+" : "") +
                allTimeReturnPercentage +
                "%)"}
              {allTimeReturnNumeric > 0 ? (
                <CaretUpOutlined
                  style={{
                    color: "green",
                  }}
                ></CaretUpOutlined>
              ) : allTimeReturnNumeric < 0 ? (
                <CaretDownOutlined
                  style={{
                    color: "red",
                  }}
                ></CaretDownOutlined>
              ) : null}
              All Time
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <b>Top Stocks: </b>
            {snapshot.positions.length === 0
              ? "N/A"
              : snapshot.positions
                  .slice(0, 2)
                  .map((position) => (
                    <Tag style={{ backgroundColor: "white" }}>
                      {position.symbol}
                    </Tag>
                  ))}
          </div>
          <div style={{ marginTop: "5px" }}>
            <b>Exchanges: </b>
            {exchanges.size === 0
              ? "N/A"
              : Array.from(exchanges)
                  .slice(0, 2)
                  .map((exchange) => (
                    <Tag style={{ backgroundColor: "white" }}>{exchange}</Tag>
                  ))}
          </div>
        </div>
        <div
          style={{
            width: windowWidth > 1210 ? "50%" : "65%",
            height: "120px",
            marginLeft: "10px",
          }}
        >
          {portfolio.oneYearGraph &&
            Object.keys(portfolio.oneYearGraph).length > 1 && (
              <MiniChangeOverTimeLineGraph
                oneYearGraphData={portfolio.oneYearGraph}
              />
            )}
        </div>
      </div>
    </Card>
  );
};

export default PortfolioCard;
