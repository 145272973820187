export const brokerageLogoMap = {
  "Wealthsimple Trade": require("assets/images/logos/wealthsimple.png"),
  "TD Ameritrade": require("assets/images/logos/td-ameritrade.png"),
  Alpaca: require("assets/images/logos/alpaca.png"),
  "Interactive Brokers": require("assets/images/logos/interactive-brokers.png"),
  Robinhood: require("assets/images/logos/robinhood.png"),
  Questrade: require("assets/images/logos/questrade.png"),
};

export const formatToDollars = (value) => {
  // convert xxxxxxxx.xxxx to xx,xxx,xxx.xx
  return value
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "0.00";
};
