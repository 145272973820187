import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  SettingOutlined,
  StockOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();

  const selectedKey = window.location.pathname.split("/")[1];
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
  });

  const SidebarItems = [
    {
      key: "/discover",
      icon: <SearchOutlined />,
      label: "Discover",
      style: {
        marginBottom: "10px",
      },
      // children: [
      //   {
      //     key: "/discover/something",
      //     label: "option1",
      //   },
      //   {
      //     key: "/discover/something2",
      //     label: "option2",
      //   },
      // ],
    },
    {
      key: "/portfolio",
      icon: <StockOutlined />,
      label: "Portfolio",
      style: {
        marginBottom: "10px",
      },
    },
    {
      key: "/settings",
      icon: <SettingOutlined />,
      label: "Settings",
      style: {
        marginBottom: "10px",
      },
    },
  ];

  const sidebarColor = "#fff";
  return (
    <Sider style={{ backgroundColor: sidebarColor }} width={220}>
      <div
        style={{
          marginTop: "16px",
          marginBottom: "16px",
          height: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={require("../assets/images/logos/selfolio.png")}
          alt="logo"
          style={{ height: "20px" }}
          draggable={false}
        />
      </div>
      <div
        style={{
          borderBottom: "1px solid #E8E8E8",
          marginLeft: "24px",
          marginRight: "24px",
        }}
      ></div>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedKey ? "/" + selectedKey : "/discover"]}
        style={{
          border: "none",
          backgroundColor: sidebarColor,
          paddingLeft: "15px",
          paddingRight: "15px",
          height: windowHeight - 110 + "px",
          paddingTop: "16px",
        }}
        items={SidebarItems}
        onClick={(e) => navigate(e.key)}
      />
    </Sider>
  );
};

export default Sidebar;
