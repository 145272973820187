import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  ReloadOutlined,
} from "@ant-design/icons";
import "./index.css";

const Login = () => {
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    authCode: "",
  });
  const [error, setError] = useState(null);
  const [isConfirmingAuth, setIsConfirmingAuth] = useState({
    UI: false,
    API: false,
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);

  const isSubmitDisabled =
    !formState.username ||
    !formState.password ||
    (isConfirmingAuth.API && !formState.authCode);

  const handleSignIn = async () => {
    if (isSubmitDisabled) return;

    setError(null);
    setIsSubmitLoading(true);

    const { username, password, authCode } = formState;
    try {
      if (isConfirmingAuth.API) {
        await Auth.confirmSignUp(username, authCode);
        sessionStorage.removeItem("email");
        setIsConfirmingAuth({ UI: true, API: false });
      }
      await Auth.signIn(username, password);
      window.location.reload();
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        setIsConfirmingAuth({ UI: true, API: true });
        await handleResendVerificationCode();
      } else {
        setError(`${error}`);
      }
    }
    setIsSubmitLoading(false);
  };

  const handleResendVerificationCode = async () => {
    setIsResendLoading(true);
    const { username } = formState;
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      setError(`${error}`);
    }
    setIsResendLoading(false);
  };

  const handleFormChange = (e) => {
    setFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  return (
    <div className="center-container">
      <div className="auth-card">
        <div className="auth-card-header">
          <h1>
            {!isConfirmingAuth.UI ? "Welcome back!" : "Verify your account"}
          </h1>
          <h6>One login for all Selfolio products</h6>
        </div>
        <Input
          name="username"
          onChange={handleFormChange}
          onPressEnter={handleSignIn}
          type="text"
          placeholder="Email"
          style={{ marginBottom: "8px" }}
        />
        <Input.Password
          name="password"
          onChange={handleFormChange}
          onPressEnter={handleSignIn}
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          style={{ marginBottom: "8px" }}
        />
        {isConfirmingAuth.UI && (
          <>
            <Input
              name="authCode"
              onChange={handleFormChange}
              type="text"
              placeholder="Verification code"
            />
            <Button
              icon={<ReloadOutlined />}
              type="link"
              style={{ marginLeft: "-12px", marginBottom: "8px" }}
              onClick={() => handleResendVerificationCode()}
              loading={isResendLoading}
            >
              Resend verification code
            </Button>
          </>
        )}
        <p style={{ color: "red" }}>{error}</p>
        <Button
          disabled={isSubmitDisabled}
          onClick={() => handleSignIn()}
          type="primary"
          style={{ width: "100%", marginTop: "24px" }}
          loading={isSubmitLoading}
        >
          {!isSubmitLoading
            ? isConfirmingAuth.UI
              ? "Confirm and log in"
              : "Log in"
            : "Loading..."}
        </Button>
        <div className="auth-card-create-account">
          <p>
            Don't have an account? <a href="/register">Create an account</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
