import React from "react";
import { Navigate } from "react-router-dom";

const ValidRoute = (props) => {
  const { route, children, isAuthenticated } = props;

  if (route.requiresAuth && !isAuthenticated) {
    console.log("Unauthorized, redirecting.");
    return <Navigate to="/login" />;
  } else if (!route.requiresAuth && isAuthenticated) {
    console.log("Already logged in, redirecting.");
    return <Navigate to="/discover" />;
  }
  return <>{children}</>;
};

export default ValidRoute;
